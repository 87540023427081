import Icon1 from "./icon1.svg";
import Icon2 from "./icon2.svg";
import Icon3 from "./icon3.svg";
import Icon4 from "./icon4.svg";
import Icon5 from "./icon5.svg";
import Icon6 from "./icon6.svg";
import Icon7 from "./icon7.svg";
import Icon8 from "./icon8.svg";

const arr = [Icon1, Icon2, Icon3, Icon4, Icon5, Icon6, Icon7, Icon8]
export default arr;
